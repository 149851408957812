(() => {
	let oldPushState = history.pushState;
	history.pushState = function pushState() {
		let ret = oldPushState.apply(this, arguments);
		window.dispatchEvent(new Event('pushstate'));
		window.dispatchEvent(new Event('locationchange'));
		return ret;
	};

	let oldReplaceState = history.replaceState;
	history.replaceState = function replaceState() {
		let ret = oldReplaceState.apply(this, arguments);
		window.dispatchEvent(new Event('replacestate'));
		window.dispatchEvent(new Event('locationchange'));
		return ret;
	};

	window.addEventListener('popstate', () => {
		window.dispatchEvent(new Event('locationchange'));
	});
})();



document.addEventListener(
	'DOMContentLoaded',
	function () {
		growthstackAnalytics();
	},
	false
);

/**
 * Checks whether a specific cookie is set.
 *
 * @param {string} name The name of the cookie.
 * @return {boolean} True if the cookie is set, false otherwise.
 */
const hasCookie = ( name ) => {
	const matchCookies = decodeURIComponent( document.cookie )
		.split( '; ' )
		.filter( ( cookie ) => cookie.indexOf( `${ name }=` ) === 0 );
	return matchCookies.length > 0;
};

/**
 * Gets the value of a cookie.
 *
 * @param {string} name The name of the cookie.
 * @return {*} The value of the cookie.
 */
const getCookie = ( name ) => {
	return decodeURIComponent( document.cookie )
		.split( '; ' )
		.filter( ( cookie ) => cookie.indexOf( `${ name }=` ) === 0 )
		.map( ( cookie ) => cookie.substring( name.length + 1 ) );
};

/**
 * Sets a session cookie.
 *
 * @param {string} name  The name of the cookie.
 * @param {string} value The value of the cookie.
 */
const setSessionCookie = ( name, value ) => {
	const path = "; path=/";
	const expires = "; expires=0";
	const sameSite = "; samesite=Strict";
	const secure = "; secure";
	document.cookie = name + '=' + value + expires + path + sameSite + secure;
};

/**
 * Sets a cookie.
 *
 * @param {string} name           The name of the cookie.
 * @param {string} value          The value to store.
 * @param {Date}   expirationDate The expiration date.
 */
const setCookie = ( name, value, expirationDate ) => {
	const now = new Date();
	now.setTime( now.getTime() + ( 365 * 24 * 60 * 60 * 1000 ) );
	const expires = "; expires="+ now.toUTCString();
	const path = "; path=/";
	document.cookie = name + '=' + value + expires + path;
};

const growthstackAnalytics = () => {
	const saveEndpoint = GROWTHSTACK_ANALYTICS.endpoint;
	const postID = GROWTHSTACK_ANALYTICS.postID ?? 0;

	const eventQ = [];

	/**
	 * Limited tracking is set when a page content type is not tracked by
	 * analytics. This is necessary because we need to track the A/B test
	 * conversions, which can happen on pages that are not tracked by
	 * analytics.
	 */
	const limitedTracking = GROWTHSTACK_ANALYTICS.limitedTracking ?? 0;

	/**
	 * Whether the user has engaged with this page or not.
	*/
	let isEngagedPage = false;

	/**
	 * Checks whether an A/B test is set on the page.
	 */
	const hasABTest = () => {
		if ( typeof GROWTHSTACK_AB_TEST === "undefined" ) {
			return false;
		}

		const id = GROWTHSTACK_AB_TEST?.ab_test?.id ?? false;
		return id === false ? false : true;
	};


	/**
	 * Gets the list of A/B test cookies.
	 *
	 * @returns {array} The current A/B test cookies.
	 */
	const ABTestCookies = () => {
		const prefix = 'growthstack-abtest-';

		return decodeURIComponent( document.cookie ).split(';')
			.filter( cookie => cookie.trim().startsWith( prefix ) && cookie.split('=').length === 2 )
			.map( cookie => {
				const cookieArray = cookie.split('=');
				return {
					id: cookieArray[0].trim().substring( prefix.length ),
					value: cookieArray[1]
				}
			} )
	};

	/**
	 * Saves an analytics event.
	 *
	 * @param {string} type The type of the event.
	 * @param {object} data The event data. The data differs based on the
	 *                      type of event.
	 */
	const queueEvent = ( type, data ) => {
		console.log( 'queueEvent', type );
		eventQ.push( {
			'type': type,
			'postID': postID,
			'data': data,
		} );
	};

	/**
	 * Saves an analytics event.
	 *
	 * @param {string} type The type of the event.
	 * @param {object} data The event data. The data differs based on the
	 *                      type of event.
	 */
	const saveEvents = () => {
		if ( eventQ.length === 0 ) {
			return;
		}
		navigator.sendBeacon(
			`${ saveEndpoint }`,
			new Blob(
				[
					JSON.stringify( {
						'events': eventQ,
					} )
				],
				{
					type: 'application/json; charset=UTF-8'
				}
			)
		);
		eventQ.length = 0;
	};

	/**
	 * Saves a new user and the landing page.
	 *
	 * A new user is a single visitor across multiple pages.
	 */
	const setUserCount = () => {
		const cookieName = 'growthstack-analytics-user';

		if ( hasCookie( cookieName ) ) {
			return;
		}

		setCookie( cookieName, true, 365 );
		queueEvent( 'newUser', {} );
		queueEvent( 'newLandingView', {} );
	};

	/**
	 * Stores the number of individual sessions.
	 *
	 */
	const setSessionCount = () => {
		const cookieName = 'growthstack-analytics-session';

		if ( hasCookie( cookieName ) ) {
			const cookieValue = JSON.parse( getCookie( cookieName ) );
			cookieValue.pages = [
				...new Set( [
					...cookieValue.pages,
					GROWTHSTACK_ANALYTICS.postID ?? 0
				] )
			];
			setSessionCookie( cookieName, JSON.stringify( cookieValue ) );
			return;
		}

		setSessionCookie( cookieName, JSON.stringify( {
			pages: [ GROWTHSTACK_ANALYTICS.postID ?? 0 ]
		} ) );

		queueEvent( 'newSession', {} );
	};

	/**
	 * Stores the number of individual site users.
	 */
	const setPageView = () => {
		queueEvent( 'pageView', {
			'ABTests': ABTestCookies(),
			'url': window.location.href,
		} );
	};

	/**
	 * Sets a new engaged session.
	 *
	 * An session is considered engaged if:
	 * 	- the user has visited at least two pages.
	 * 	- the user has spent enough time on the site (i.e. 10 seconds).
	 *  - TBD: has converted.
	 */
	const setNewEngagedSession = () => {
		const engagementCookieName = 'growthstack-analytics-engaged';
		const sessionCookieName = 'growthstack-analytics-session';

		if ( hasCookie( engagementCookieName ) ) {
			return;
		}

		if ( hasCookie( sessionCookieName ) ) {
			const sessionCookieValue = JSON.parse( getCookie( sessionCookieName ) );

			if ( sessionCookieValue.pages.length > 1 ) {
				setSessionCookie( engagementCookieName, true );
				queueEvent( 'newEngagedSession', {} );
				return;
			}
		}

		setTimeout( () => {
			setSessionCookie( engagementCookieName, true );
			queueEvent( 'newEngagedSession', {} );
		}, "10000" );
	};

	/**
	 * Sets a new engaged page.
	 *
	 * An visitor is considered engaged in the page when:
	 * 	- the visitor spent enough time on the site (i.e. 10 seconds).
	 * 	- the visitor clicked a link with a different URL (this means a URL
	 *  with different: protocol, hostname, pathname, or search params).
	 */
	const setNewEngagedPage = () => {
		// Saves the event when the visitor has been on the page for 10 seconds.
		setTimeout( () => {
			if ( this.isEngagedPage === true ) {
				return;
			}
			this.isEngagedPage = true;
			queueEvent( 'hasEngagedPage', {
				'ABTests': ABTestCookies(),
			});
		}, "10000" );

		// Saves the event when the visitor clicks on a link that redirects
		// with a different URL.
		document.addEventListener( 'click', event => {
			if ( ! event.target.href ) {
				return;
			}

			const currentURL = new URL( window.location.href );
			const targetURL = new URL( event.target.href );
			targetURL.hash = currentURL.hash;

			if ( this.isEngagedPage === true || currentURL.href === targetURL.href ) {
				return;
			}

			this.isEngagedPage = true;
			queueEvent( 'hasEngagedPage', {
				'ABTests': ABTestCookies(),
			});
		});
	};

	/**
	 * User time on page.
	 *
	 * @todo Replace with Performance API (https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure)
	 */
	const setTimeOnPage = () => {
		let startDate = new Date();
		let timeOnPage = 0;

		const timeTrackRestart = function() {
			startDate = new Date();
		};

		const timeTrackPause = function() {
			const endDate = new Date();
			const spentTime = endDate.getTime() - startDate.getTime();
			timeOnPage += spentTime;
		};

		const timeTrackEnd = function() {
			const endDate = new Date();
			const spentTime = endDate.getTime() - startDate.getTime();
			timeOnPage += spentTime;
			queueEvent( 'timeOnPage', {
				'timeOnPage': timeOnPage,
				'ABTests': ABTestCookies(),
			});
			saveEvents();
		};

		window.addEventListener( 'focus', timeTrackRestart );
		window.addEventListener( 'blur', timeTrackPause );
		window.addEventListener( 'beforeunload', timeTrackEnd );
	};

	/**
	 * Location change.
	 *
	 * Sends an event when the page location changes. This is used for goal
	 * tracking.
	 */
	const setLocationChange = () => {
		const locationChange = function() {
			queueEvent( 'locationChange', {
				'ABTests': ABTestCookies(),
				'URL': window.location.href
			});
		};
		window.addEventListener( 'locationchange', locationChange);
		locationChange();
	};

	const init = () => {
		if ( ! limitedTracking ) {
			setUserCount();
			setSessionCount();
			setPageView();
			setTimeOnPage();
			setNewEngagedSession();
			setNewEngagedPage();
		}

		setLocationChange();

		setInterval( () => {
			saveEvents();
		}, "11000" );
	};

	/**
	 * If the page has an A/B test running, delay the execution until the
	 * variant content is set.
	 */
	if ( hasABTest() ) {
		document.addEventListener( 'GrowthstackABTestReplacementAfter', init );
	} else {
		init();
	}

};

